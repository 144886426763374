<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">评论管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
             <div title="班級编码" class="searchboxItem ci-full">
              <span class="itemLabel">班級编码:</span>
              <el-input
                v-model="projectCode"
                clearable
                placeholder="请输入班級编码"
                size="small"
              ></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                clearable
                placeholder="请输入课程名称"
                size="small"
              ></el-input>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button type="primary" class="bgc-bv" round @click="seeList"
                >评论列表</el-button
              >
              seeList
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
               @sort-change="changeSort"
              :default-sort = "{prop: 'replyTime', order: 'descending'}"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200"
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
                min-width="200"
              />
              <el-table-column
                label="被举报人次"
                align="center"
                show-overflow-tooltip
                prop="reportNum"
                min-width="160"
                 :sort-by="'reportNum'"
                sortable="custom"
              />
              <el-table-column
                label="参与人次"
                align="center"
                show-overflow-tooltip
                prop="userNum"
                min-width="100"
                   :sort-by="'userNum'"
                sortable="custom"
              />
              <el-table-column
                  label="最后回复时间"
                  align="center"
                  show-overflow-tooltip
                  prop="replyTime"
                   :sort-orders="['ascending', 'descending']"
                sortable="custom"
                  min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.replyTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="160px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="seeInfo(scope.row)"
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      courseName: "", //课程名称
      projectName: "", //班级名称
      projectCode: "", //班级编码
    };
  },
  watch: {},
  created() {},
  computed: {},
  mounted() {},
  methods: {
    changeSort(row, clounm) {
      if (row.prop == "reportNum") {
        console.log(row.order);
        this.reportNum = row.order;
        this.userNum = "";
        this.replyTime = "";
      }
      if (row.prop == "userNum") {
        this.reportNum = '';
        this.userNum = row.order;
        this.replyTime = "";
      }
      if (row.prop == "replyTime") {
        this.reportNum = '';
        this.userNum = "";
        this.replyTime = row.order;
      }
      this.getData();
    },
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.reportNum) {
        params.reportNumOrder = this.reportNum;
      }
      if (this.userNum) {
        params.userNumOrder = this.userNum;
      }
      if (this.replyTime) {
        params.replyTimeOrder = this.replyTime;
      }
      this.doFetch({
        url: "/biz/course/discuss/pageList",
        params,
        pageNum,
      });
    },
    // 点击查看
    seeInfo(row) {
      this.$router.push({
        path: "/web/workOrder/commentManagementInfo",
        query: {
          row: JSON.stringify(row),
        },
      });
    },
    // 查看列表
    seeList() {
      this.$router.push({
        path: "/web/workOrder/commentManagementList",  
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope></style>